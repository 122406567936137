// loaded as prioroty after jquery

/*

//due to featherlight.js - cannot override functions

var oldshow = $.fn.show;
$.fn.show = function()
{
    var ret = oldshow.apply(this, arguments);
    this.removeClass("display-none");
    return ret;
};

var oldhide = $.fn.hide;
$.fn.hide = function()
{
    var ret = oldhide.apply(this, arguments);
    this.addClass("display-none");
    return ret;
};
*/

$.fn.reveal = function() {
    this.removeClass("display-none");
}

$.fn.conceal = function() {
    this.addClass("display-none");
}
